import { ThemeContext, WalletConnectorContext } from "../../../contexts";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Space,
  Switch,
  theme,
  Typography,
} from "antd";
import { useContext } from "react";
import { FlashHeader, StyledHeader } from "./index.styled";
import { Link, useNavigate } from "react-router-dom";
import { APP_NAME, NETWORKS, TOKEN_SYMBOL, shorternAddress } from "../../../_shared";


const { useToken } = theme;

const { Text } = Typography;

interface MainHeaderProps {
  text?: string;
  flashHeader?: boolean;
}

const dropdownItems: MenuProps["items"] = [
  {
    key: "created",
    label: (
      <Link className={"font-medium text-lg"} to="/projects/created">
        Created
      </Link>
    ),
  },
  {
    key: "sacrificed",
    label: (
      <Link className={"font-medium text-lg"} to="/projects/sacrifices">
        Contributed
      </Link>
    ),
  },
  {
    key: "following",
    label: (
      <Link className={"font-medium text-lg"} to="/projects/following">
        Following
      </Link>
    ),
  },
  {
    key: "disconnect",
    label: <span className={"font-medium text-lg"}>Disconnect</span>,
  },
];

export const MainHeader = (props: MainHeaderProps) => {
  const { flashHeader = true } = props;
  const { setThemeType, themeType } = useContext(ThemeContext);
  const {  connect, disconnect, address, chainId } = useContext(WalletConnectorContext);
  const onChange = (checked: boolean) => {
    if (!setThemeType) return;
    if (checked) {
      setThemeType("light");
    } else {
      setThemeType("dark");
    }
  };

  console.log("chain id", chainId)
  const navigate = useNavigate();
  const { token } = useToken();
  const announcement = (
    <Space className="mx-20">
      <Text>{APP_NAME.toUpperCase()} FUNDRAISER IS LIVE !!!</Text>
      <Button
        ghost
        size="large"
        className="create-btn !border-white/8"
        onClick={() => {
          navigate("/sac/8");
        }}
      >
        <Text className="white m-0 p-0 font-1">Contribute Now!</Text>
      </Button>
    </Space>
  );

  return (
    <>
    {(chainId && !NETWORKS.find((el) => el.chainId === String(chainId))) && (
        <div className="bg-black text-center bg-opacity-70 content-center justify-center" style={{ position: 'absolute', width: '100%', zIndex: 1000, height: '100vh' }}>
          <div>
        <h2  className="text-center">Unsupported Network</h2>
        <p>Please switch to one of</p>
        <div>
          {NETWORKS.map(({label})=>(
            <p key={label}>{label.toUpperCase()}</p>
          ))}
            </div>
          </div>
          <div><Button onClick={disconnect}>Disconnect</Button></div>
      </div>
    )}
      {/* {flashHeader ? (
        <FlashHeader token={token}>
          <div className="flex-content" style={{ minWidth: 2024 }}>
            {announcement}
            {announcement}
            {announcement}
            {announcement}
            {announcement}
          </div>
        </FlashHeader>
      ) : null} */}
      <StyledHeader token={token}>
        <div className="logo-container">
          {/* <Button className="menu-button" type="text" >
                        <MenuOutlined />
                    </Button> */}
          {/* <img src="/logo-sm.svg" alt="...." /> */}
          <Link to="/">
            <img src="/logo.png" alt="...." className="w-[60px]" />
          </Link>
          <Link to="/">
            <Text className="__logo-text">{APP_NAME}</Text>
          </Link>
        </div>

        <div className="xl:flex  hidden">
          <Form
            name="form_one"
            layout="vertical"
            // form={form}
            // onFinish={onFinish}
            autoComplete="off"
            requiredMark={"optional"}
          >
            <Form.Item
              name="search"
              rules={[
                {
                  required: true,
                  message: "Input project name",
                },
              ]}
            >
              <Input
                style={{ visibility: "hidden", width: 100 }}
                placeholder="Enter project name"
                className="border-none"
                suffix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </div>

        <div className="lg:flex gap-x-8 items-center hidden">
          {/* <Button
            ghost
            className="create-btn !border-white"
            onClick={() => {
              navigate("https://solidbase.xyz/");
            }}
          >
            Contribute Now
          </Button> */}
          <a rel="noreferrer" href="/projects">
            Projects
          </a>
          <Link rel="noreferrer" to="/#features">
            Features
          </Link>
          <Link rel="noreferrer" to="/#pricing">
            Pricing
          </Link>
          <Link rel="noreferrer" to="/#psac-token">
          {TOKEN_SYMBOL} Token
          </Link>
          <Link rel="noreferrer" to="/#faq">
            FAQs
          </Link>
          <Link rel="noreferrer" to="/stake">
            Stake
          </Link>
          <Link rel="noreferrer" to="/faucet">
            Faucet
          </Link>

          {/* <Link
            rel="noreferrer"
to        href="/#faq"
            
          >
            Info
          </Link> */}
        </div>

        <div className="connect-container">
          {address ? (
            <Space size={20}>
              <Button
                className="pl-4 pr-4 pt-2 pb-2"
                type="primary"
                size={"large"}
                onClick={() => navigate("/projects/new")}
              >
                Create Project
              </Button>
              <Dropdown
                className="pl-4 pr-4 pt-1 pb-1"
                menu={{
                  items: dropdownItems,
                  onClick: ({ key }) => {
                    if (key === "disconnect" && disconnect) {
                      disconnect();
                    }
                  },
                }}
                overlayClassName={"!w-[200px]"}
                trigger={["click"]}
              >
                <a
                  className={
                    "inline-flex number-font items-center justify-between rounded-md border border-solid flex-shrink-0 border-primary h-[32px] px-[12px]"
                  }
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    <span className={"font-medium"}>
                      {shorternAddress(address)}
                    </span>
                    <DownOutlined />
                  </Space>
                </a>
                {/*<Avatar*/}
                {/*  size={"large"}*/}
                {/*  className={"cursor-pointer border-2 border-primary"}*/}
                {/*  src={generateAvatarURL(address)}*/}
                {/*/>*/}

                {/*<Button*/}
                {/*  // onClick={disconnect}*/}
                {/*  className="disconnect-wallet"*/}
                {/*  type="primary"*/}
                {/*  ghost*/}
                {/*>*/}
                {/*  ({`${shorternAddress(address)}`})<b> Disconnect</b>*/}
                {/*</Button>*/}
              </Dropdown>
            </Space>
          ) : (
            <Button
              size={"large"}
              onClick={connect}
              type={"primary"}
              className="connect-wallet"
            >
              Connect Wallet
            </Button>
          )}
          <Switch
            style={{ display: "none" }}
            checked={themeType == "light"}
            onChange={onChange}
          />
        </div>
      </StyledHeader>
    </>
  );
};
